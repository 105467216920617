<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8333 5L5.41814 6.08303C5.11565 6.14353 4.9644 6.17377 4.84123 6.24733C4.73234 6.31236 4.63973 6.40139 4.57046 6.50763C4.4921 6.6278 4.45591 6.77774 4.38352 7.07761L1.66663 18.3333M1.66663 18.3333L12.9223 15.6164C13.2222 15.5441 13.3722 15.5079 13.4923 15.4295C13.5986 15.3602 13.6876 15.2676 13.7526 15.1587C13.8262 15.0356 13.8564 14.8843 13.9169 14.5818L15 9.16666M1.66663 18.3333L7.98829 12.0117M17.3905 6.55719L13.4428 2.60947C13.1128 2.27946 12.9477 2.11445 12.7575 2.05263C12.5901 1.99825 12.4098 1.99825 12.2424 2.05263C12.0522 2.11445 11.8872 2.27946 11.5571 2.60947L10.9428 3.22385C10.6128 3.55387 10.4477 3.71888 10.3859 3.90915C10.3315 4.07652 10.3315 4.25681 10.3859 4.42418C10.4477 4.61445 10.6128 4.77946 10.9428 5.10947L14.8905 9.05719C15.2205 9.3872 15.3855 9.55221 15.5758 9.61403C15.7431 9.66841 15.9234 9.66841 16.0908 9.61403C16.2811 9.55221 16.4461 9.3872 16.7761 9.05719L17.3905 8.44281C17.7205 8.11279 17.8855 7.94778 17.9473 7.75751C18.0017 7.59014 18.0017 7.40985 17.9473 7.24248C17.8855 7.05221 17.7205 6.8872 17.3905 6.55719ZM9.16662 9.16666C10.0871 9.16666 10.8333 9.91286 10.8333 10.8333C10.8333 11.7538 10.0871 12.5 9.16663 12.5C8.24615 12.5 7.49996 11.7538 7.49996 10.8333C7.49996 9.91286 8.24615 9.16666 9.16662 9.16666Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPenTool',
}
</script>
